/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SliderWidget {
    &-FigureVideo,
    &-FigureImage {
        padding: 0;
        height: 750px;
        overflow: hidden;
    }

    &-Video {
        background-color: $primary5;
        position: absolute;
        inset: 0;

        video {
            object-fit: cover;
            object-position: center;
        }

        .react-player__preview {
            position: relative;
            z-index: 1;

            &:before {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
            }

            .react-player__shadow {
                position: absolute;
                left:  16px;
                bottom: 16px;
                height: 24px !important;
                width: 24px !important;
                background: $primary50 !important;
            }

            .react-player__play-icon {
                border-width: 6px 0px 6px 10px !important;
                margin-left: 4px !important;
                border-color: transparent transparent transparent white;
            }



            svg {
                width: 55px;
                height: 55px;

                path {
                    fill: $neutral10;
                }
            }
        }
    }

    .slick {
        &-arrow {
            --arrow-inset-inline: 0;
            --arrow-height: 35px;
            --arrow-width: 35px;

            @include desktop {
                --arrow-inset-inline: 35px;
                --arrow-height: 45px;
                --arrow-width: 45px;
            }

            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            height: var(--arrow-height);
            width: var(--arrow-width);
            z-index: 1;

            svg {
                height: var(--arrow-height);
                width: var(--arrow-width);
            }
        }

        &-next {
            right: var(--arrow-inset-inline);
        }

        &-prev {
            left: var(--arrow-inset-inline);
        }
    }

    &-Figcaption {
        left: 50% !important;
        width: 50%;
        padding-right: 20px;
        max-width: 630px;
        text-align: left !important;
        z-index: 2;

        a.Button {
            font-weight: bold;
        }
    }

    &-Figure {
        @include desktop {
            @include aspect-ratio(40%, ".Image");
            @include aspect-ratio(40%, ".SliderWidget-FigureVideo");
        }
    }

    &_isOpinion {
        max-width: 1056px;
        margin: 0 auto;

        .Slider-Arrow {
            background-color: $primary50;
            width: 41px;
            height: 41px;
            border-radius: 50%;

            svg {
                fill: #ffffff;
                width: 18px;
            }

            &_isNext {
                right: 0;
            }

            &_isPrev {
                left: 0;
            }
        }
    }

    &-Opinion {
        display: flex;
        justify-content: center;
        align-items: center;

        &Wrapper {
            display: grid;
            grid-template-columns: 310px 420px;
            grid-gap: 62px;
            align-items: center;
            margin: 0 auto;
        }

        &Image {
            width: 310px;
            height: 310px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &Content {
            h3 {
                margin-top: 0;
                margin-bottom: 30px;
            }
        }

        &Position {
            margin-top: 20px;
        }
    }
}

@include tablet {
    .SliderWidget {
        &_isOpinion {
            max-width: 650px;

            .Slider-Arrow {
                &_isNext {
                    right: -45px;
                }

                &_isPrev {
                    left: -45px;
                }
            }
        }

        &-Opinion {
            &Wrapper {
                grid-template-columns: 210px 360px;
                grid-gap: 30px;
            }

            &Image {
                width: 210px;
                height: 210px;
            }
        }
    }
}

@include mobile {
    .SliderWidget {
        &-Figcaption {
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            max-width: 630px;
            width: 100%;
            text-align: center !important;

            a.Button {
                font-weight: bold;
            }
        }

        &_isOpinion {
            max-width: 100%;
            padding: 0 30px;

            .Slider-Arrow {
                &_isNext {
                    right: -5px;
                }

                &_isPrev {
                    left: -5px;
                }
            }
        }

        &-Opinion {
            &Wrapper {
                grid-template-columns: 1fr;
                text-align: center;
            }

            &Image {
                width: 210px;
                height: 210px;
                margin: 0 auto;
            }
        }
    }
}
